import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, LinearProgress, List, ListItem, ListItemText, MenuItem, Modal, OutlinedInput, Select } from '@mui/material';
import * as React from 'react';
import { AudienceRepository } from '../../Repository/AudienceRepository';
import { ScreenConfigProps } from './ScreenDisplay';
import { StreamsConfigProps } from './Templates/Streams';
import { Edit as EditIcon, Search as SearchIcon } from '@mui/icons-material';
import { Platform } from '../../Model/Types';

export type FilterProps = {
    onSave: (screenProps: ScreenConfigProps) => void;
}

export type SelectMap = {
    label: string;
    value: string;
}

export enum FilterType{
    ArtistStreams = 'artist_streams',
    ArtistStreamsEstimated = 'artist_streams_estimated',
    TrackStreams = 'track_streams',
    TrackStreamsEstimated = 'track_streams_estimated',
}

export const Filter = (props: FilterProps): JSX.Element => {
    const [searchedTracks, setSearchedTracks] = React.useState<SelectMap[]>([]);
    const [searchedTracksLoading, setSearchedTracksLoading] = React.useState<boolean>(false);
    const [trackValue, setTrackValue] = React.useState<SelectMap | null>(null);
    const [trackSearchText, setTrackSearchText] = React.useState<string>('');

    const [searchedArtists, setSearchedArtists] = React.useState<SelectMap[]>([]);
    const [searchedArtistsLoading, setSearchedArtistsLoading] = React.useState<boolean>(false);
    const [artistValue, setArtistValue] = React.useState<SelectMap | null>(null);
    const [artistSearchText, setArtistSearchText] = React.useState<string>('');

    const [selectedPlatform, setSelectedPlatform] = React.useState<SelectMap | null>();

    const [selectedFilter, setSelectedFilter] = React.useState<SelectMap | null>();

    const [showTrackModal, setShowTrackModal] = React.useState<boolean>(false);
    const [showArtistModal, setShowArtistModal] = React.useState<boolean>(false);

    const filters: SelectMap[] = [
        {
            label: 'Artist Streams',
            value: FilterType.ArtistStreams
        },
        {
            label: 'Artist Streams (Estimated)',
            value: FilterType.ArtistStreamsEstimated
        },
        {
            label: 'Track Streams',
            value: FilterType.TrackStreams
        },
        {
            label: 'Track Streams (Estimated)',
            value: FilterType.TrackStreamsEstimated
        }
    ];

    const platforms: SelectMap[] = [
        {
            label: 'Spotify',
            value: Platform.SPOTIFY
        },
        {
            label: 'YouTube',
            value: Platform.YOUTUBE
        }
    ];

    const searchTracks = (keywords: string) => {
        setSearchedTracksLoading(true);
        const audienceRepository = new AudienceRepository();
        audienceRepository.searchTracks(keywords).then((responseData) => {
            if(responseData.items){
                const newTrackList: SelectMap[] = Array.from(responseData.items, (item: any) => {
                    const newItem: SelectMap = {
                        value: item.id,
                        label: item.name
                    };
                    
                    return newItem;
                });
    
                setSearchedTracks(newTrackList);
            }

            setSearchedTracksLoading(false);
        });
    }


    const searchArtists = (keywords: string) => {
        setSearchedArtistsLoading(true);
        const audienceRepository = new AudienceRepository();
        audienceRepository.searchArtists(keywords).then((responseData) => {
            if(responseData.items){
                const newArtistList: SelectMap[] = Array.from(responseData.items, (item: any) => {
                    const newItem: SelectMap = {
                        value: item.id,
                        label: item.name
                    };
                    
                    return newItem;
                });
    
                setSearchedArtists(newArtistList);
            }

            setSearchedArtistsLoading(false);
        });
    }

    React.useEffect(() => {
        setSelectedFilter(null);
        setArtistValue(null);
        setTrackValue(null);
    }, [selectedPlatform]);

    return <div>
        <Modal
            open={showTrackModal}
            sx={{textAlign: 'center', overflow: 'scroll', maxHeight: '100%'}}
            onClose={e => setShowTrackModal(false)}
        >
            <Box bgcolor="white" sx={{p: 2, m: 4, maxWidth: '700px', margin: 'auto'}}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if(trackSearchText){
                            searchTracks(trackSearchText);
                        }
                    }}
                >
                    <FormControl variant="outlined" fullWidth={true}>
                        <InputLabel>Search Tracks</InputLabel>
                        <OutlinedInput
                            type="text"
                            onChange={(e) => {
                                setTrackSearchText(e.target.value);
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={e => {
                                            if(trackSearchText){
                                                searchTracks(trackSearchText);
                                            }
                                        }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Search Tracks"
                        />
                    </FormControl>
                    {
                        searchedTracksLoading &&
                            <LinearProgress sx={{mt: 2}} />
                    }
                    {
                        ( searchedTracks && searchedTracks.length > 0 ) && 
                        <List>
                            {
                                searchedTracks.map((searchTrack: any) => {
                                    return <ListItem
                                        sx={{backgroundColor: 'whitesmoke', borderBottom: '1px solid white'}}
                                        key={searchTrack.value}
                                        secondaryAction={
                                            <Button
                                                variant="outlined"
                                                onClick={(e) => {
                                                    setSearchedTracks([]);
                                                    setShowTrackModal(false);
                                                    setTrackValue(searchTrack);
                                                }}
                                            >
                                              Select
                                            </Button>
                                        }
                                    >
                                        <ListItemText
                                            primary={searchTrack.label}
                                        />
                                    </ListItem>;
                                })
                            }
                        </List>

                    }
                        
                </form>
            </Box>
            
        </Modal>

        <Modal
            open={showArtistModal}
            sx={{textAlign: 'center', overflow: 'scroll', maxHeight: '100%'}}
            onClose={e => setShowArtistModal(false)}
        >
            <Box bgcolor="white" sx={{p: 2, m: 4, maxWidth: '700px', margin: 'auto'}}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if(artistSearchText){
                            searchArtists(artistSearchText);
                        }
                    }}
                >
                    <FormControl variant="outlined" fullWidth={true}>
                        <InputLabel>Search Artists</InputLabel>
                        <OutlinedInput
                            type="text"
                            onChange={(e) => {
                                setArtistSearchText(e.target.value);
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={e => {
                                            if(artistSearchText){
                                                searchArtists(artistSearchText);
                                            }
                                        }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Search Artists"
                        />
                    </FormControl>
                    {
                        searchedArtistsLoading &&
                            <LinearProgress sx={{mt: 2}} />
                    }
                    {
                        ( searchedArtists && searchedArtists.length > 0 ) && 
                        <List>
                            {
                                searchedArtists.map((searchArtist: any) => {
                                    return <ListItem
                                        sx={{backgroundColor: 'whitesmoke', borderBottom: '1px solid white'}}
                                        key={searchArtist.value}
                                        secondaryAction={
                                            <Button
                                                variant="outlined"
                                                onClick={(e) => {
                                                    setSearchedArtists([]);
                                                    setShowArtistModal(false);
                                                    setArtistValue(searchArtist);
                                                }}
                                            >
                                              Select
                                            </Button>
                                        }
                                    >
                                        <ListItemText
                                            primary={searchArtist.label}
                                        />
                                    </ListItem>;
                                })
                            }
                        </List>

                    }
                        
                </form>
            </Box>
            
        </Modal>

        <FormControl fullWidth sx={{mt: 2, mb: 2}} variant="filled">
            <InputLabel>Service</InputLabel>
            <Select
                
                fullWidth={true}
                value={selectedPlatform ? selectedPlatform.value : ''}
                onChange={(e) => {
                    const newValue = e.target.value;

                    setArtistValue(null);
                    setTrackValue(null);
                    setSelectedFilter(null);

                    if( newValue ){
                        const newPlatform = platforms.find(platform => platform.value === newValue);
                        setSelectedPlatform(newPlatform);
                    }else{
                        setSelectedPlatform(null);
                    }
                }}
            >
                {
                    platforms && platforms.map((platform) => <MenuItem key={platform.value} value={platform.value}>{platform.label}</MenuItem>)
                }
            </Select>
        </FormControl>

        {
            selectedPlatform &&
                <FormControl fullWidth sx={{mb: 2}} variant="filled">
                    <InputLabel>Template Type</InputLabel>
                    <Select
                        value={selectedFilter ? selectedFilter.value : ''}
                        fullWidth={true}
                        label="Template Type"
                        onChange={(e) => {
                            const newValue = e.target.value;

                            setArtistValue(null);
                            setTrackValue(null);

                            if( newValue ){
                                const newFilter = filters.find(filter => filter.value === newValue);
                                setSelectedFilter(newFilter);
                            }else{
                                setSelectedFilter(null);
                            }
                        }}
                    >
                        {
                            filters && filters.map((filter) => <MenuItem key={filter.value} value={filter.value}>{filter.label}</MenuItem>)
                        }
                    </Select>
                </FormControl>
        }
        {
            ( selectedFilter && ( selectedFilter.value === FilterType.ArtistStreams || selectedFilter.value === FilterType.ArtistStreamsEstimated ) ) &&
                <FormControl variant="filled" fullWidth={true} sx={{mb: 2}}>
                    <InputLabel>Selected Artist</InputLabel>
                    <OutlinedInput
                        disabled={true}
                        type="text"
                        value={artistValue ? artistValue.label : ''}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                onClick={e => setShowArtistModal(true)}
                            >
                            <EditIcon />
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </FormControl>
            
        }

        {
            ( selectedFilter && ( selectedFilter.value === FilterType.TrackStreams || selectedFilter.value === FilterType.TrackStreamsEstimated ) ) &&
                <FormControl variant="filled" fullWidth={true} sx={{mb: 2}}>
                    <InputLabel>Selected Track</InputLabel>
                    <OutlinedInput
                        disabled={true}
                        type="text"
                        value={trackValue ? trackValue.label : ''}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                onClick={e => setShowTrackModal(true)}
                            >
                            <EditIcon />
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </FormControl>
        }

        {
            ( selectedPlatform === null || ( trackValue === null && artistValue === null ) === false ) &&
                <Button
                    variant="contained"
                    fullWidth={true}
                    color="success"
                    onClick={() => {
                        if( selectedPlatform ){
                            let newStreamData: StreamsConfigProps = {
                                platform: selectedPlatform.value as Platform
                            };

                            if(trackValue){
                                newStreamData.trackId = trackValue.value
                            }else if(artistValue){
                                newStreamData.artistId = artistValue.value
                            }

                            const newData: ScreenConfigProps = {
                                streams: newStreamData
                            };

                            props.onSave(newData);
                        }

                    }}
                >
                    Save
                </Button>

        }

    </div>;
}
